.intro {
    background-image: url("../../assests/svg/landing-image.svg");
    background-size: 54% auto;
    height: 100vh;
    background-position: right top;
    background-repeat: no-repeat;
    justify-content: flex-end;
    align-items: center;
    background-color: #F9F9F9;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    opacity: 0.4;
    transition: opacity 3s ease;
}

.intro.visible {
    opacity: 1;
}

@media (max-width: 1200px) {
    .intro {
        background-size: 60% auto;
    }
}

@media (max-width: 992px) {
    .intro {
        background-size: 70% auto;
        height: fit-content;
    }
}

@media (max-width: 768px) {
    .intro {
        background-size: 80% auto;
        justify-content: center; 
        text-align: center; 
        height: fit-content;
    }
}

@media (max-width: 576px) {
    .intro {
        background-size: 90% auto;
    }
}

.about-content{
    display: flex;
    flex-direction: row-reverse;
    margin: 0 auto;
    width: 80%;
    padding: 120px 0;
}

.about-left{
    flex: 50%;
    display: block;
    padding-left: 4%;
}

.about-right{
    flex: 50%;
    display: flex;
}

.about-img{
    width: 100%;
    align-items: center;
}

@media only screen and (max-width: 600px) {
    .about-content{
        display: block;
        margin: 0 auto;
        width: 96%;
        padding: 60px 0;
    }

    .about-img{
        width: 100%;
        height: 50%;
        object-fit: cover;
        margin-top: 20px;
    }

    .container-title{
        text-align: center;
    }

    .container-subtitle {
        text-align: center;
    }

    .container-description{
        width: 100% !important;
        text-align:justify;
    }
    
}

.slide-in {
    transform: translateX(-100%);
    opacity: 0;
    animation: slideIn 1s forwards;
}

@keyframes slideIn {
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

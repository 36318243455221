@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

/* Default styles */
.container-title {
    font-family: 'Poppins', sans-serif; 
    font-size: 18px;
    color: #016FC1;
    margin: 0;
    text-transform: uppercase;
}

.container-subtitle {
    font-family: 'poppins';
    font-size: 32px;
    color: #121212;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
}

.container-description {
    font-family: 'poppins';
    font-size: 16px;
    color: #121212;
    margin-top: 20px;
    line-height: 1.2;
    width: 80%;
}

.fade-in {
    opacity: 0;
    animation: fadeIn 2s forwards;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

/* Responsive styles */
@media only screen and (max-width: 600px) {
    .container-title {
        font-size: 14px;
    }
    .container-subtitle {
        font-size: 24px;
    }
    .container-description {
        font-size: 14px;
        line-height: 1.4;
    }
}

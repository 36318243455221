.contact-content{
    display: block;
    margin: 40px auto;
    width: 80%;
}

.contact-container{
    display: flex;
    margin-top: 40px;
    padding: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.center-text {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contact-left{
    flex: 40%;
    display: block;
    background-image: url('https://a2zcleangimages.s3.amazonaws.com/contact-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 400px;
    padding: 40px;
}

.contact-left-title{
    font-family: 'poppins'; 
    font-size: 28px;
    color: white;
    text-align: left;
    font-weight: 600;
}


.contact-left-description{
    font-family: 'poppins'; 
    font-size: 16px;
    color: #C9C9C9;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 80px;
    font-weight: 300;
}

.contact-detail{
    display: flex;
    margin-top: 40px;
}

.contact-info{
    font-family: 'poppins'; 
    font-size: 16px;
    color: white;
    text-align: left;
}

.phone-icon{
    background: url('../../assests/images/phone.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 20px;
    width: 20px;
    margin-right: 30px;
}

.message-icon{
    background: url('../../assests/images/message.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 16px;
    width: 20px;
    margin-right: 30px;
}

.location-icon{
    background: url('../../assests/images/location.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 20px;
    width: 20px;
    margin-right: 30px;
}

.contact-right{
    flex: 60%;
    padding: 80px 40px;
}

.contact-form {
    display: flex;
    flex-wrap: wrap;
    gap: 16px; 
}

.contact-form-group {
    flex: 1 1 45%; 
    min-width: calc(50% - 16px); 
    box-sizing: border-box; 
    margin-top: 10px;
}

.contact-form-group-textarea {
    flex: 1 1 100%; 
    min-width: 100%;
    box-sizing: border-box;
    margin-top: 10px;
}

.contact-label{
    font-family: 'poppins'; 
    font-size: 16px;
    color: #121212;
    margin-bottom: 10px;
    width: 100%;
}

.contact-input{
    font-family: 'poppins'; 
    font-size: 16px;
    color: #121212;
    width: 96%;
    padding: 2px 0;
    border: none;
    border-bottom: 1px solid #121212;
    margin-top: 10px;
    background-color: transparent;
}

.contact-input::placeholder, .contact-textarea::placeholder{
    font-family: 'poppins'; 
    font-size: 16px;
    color: #a9a7a7;
}

.contact-textarea{
    font-family: 'poppins'; 
    font-size: 16px;
    color: #121212;
    width: 98%;
    padding: 2px 0;
    border: none;
    border-bottom: 1px solid #121212;
    height: 20px;
    margin-top: 10px;
    background-color: transparent;
}

.contact-button{
    background-color: #011C2A;
    color: white;
    font-family: 'poppins'; 
    font-size: 16px;
    padding: 15px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    margin-left: auto;
    margin-right: 10px;
}

.status-message {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: green;
    margin-top: 20px;
    text-align: center;
}

.error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
    font-family: 'Poppins', sans-serif;
}


@media only screen and (max-width: 600px) {
    .contact-content{
        width: 100%;
    }
    .contact-container{
        display: block;
        padding: 0;
        box-shadow: none;
    }
    .contact-left{
        padding: 40px 20px;
        height: fit-content;
    }
    .contact-left-title{
        font-size: 16px;
    }
    .contact-left-description{
        font-size: 14px;
    }

    .contact-right {
        padding: 40px;
    }
    .contact-form {
        display: block;
    }
    .contact-form-group, .contact-form-group-textarea {
        margin-top: 24px;
    }
    .contact-button{
        margin: 20px auto;
        display: block;
        font-size: 14px;
    }
}
.landing-container{
    height: 100vh;
    display: flex;
    align-items: center;
}
.landing-content {
    width: 80%;
    margin: 6vw auto;
    display: block;
    opacity: 0;
    animation: fadeIn 1.5s ease-in-out forwards;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.landing-title {
    font-family: 'poppins';
    font-size: 60px;
    color: #121212;
    margin-bottom: 20px;
    width: 50%;
    font-weight: bold;
    line-height: 1.2;
    opacity: 0;
    animation: slideUp 1s ease-in-out forwards;
    animation-delay: 0.5s;
}

.landing-line {
    width: 25%;
    height: 15px;
    background-color: #FDC221;
    margin-bottom: 20px;
    opacity: 0;
    animation: slideUp 1s ease-in-out forwards;
    animation-delay: 1s;
}

.landing-subtitle {
    font-family: 'poppins';
    font-size: 16px;
    color: #121212;
    margin-bottom: 20px;
    width: 36%;
    line-height: 1.2;
    opacity: 0;
    animation: slideUp 1s ease-in-out forwards;
    animation-delay: 1.5s;
}

.landing-button {
    background-color: #121212;
    color: white;
    padding: 18px 30px;
    border-radius: 20px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    font-family: 'poppins';
    margin-top: 20px;
    font-weight: bold;
    opacity: 0;
    animation: slideUp 1s ease-in-out forwards;
    animation-delay: 2s;
}

@media only screen and (max-width: 960px) {
    .landing-content {
        width: 96%;
    }
    .landing-title {
        font-size: 24px;
        width: 100%;
        margin-top: 74vw;
        text-align: center;
    }
    .landing-line {
        width: 70%;
        margin: 10px auto;
        height: 8px;
    }
    .landing-subtitle {
        font-size: 14px;
        width: 100%;
        line-height: 1.4;
        text-align: center;
    }
    .landing-button {
        margin: 20px auto;
        display: block;
    }
}

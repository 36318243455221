.service-content{
    padding: 20px 0;
    display: block;
}
.service-container {
    width: 100%;
}

.service-box {
    position: relative;
    text-align: center;
    color: white;
    margin-top: 40px;
    overflow: hidden;
}

.cleaning-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.cleaning-details {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.cleaning-title{
    font-family: 'poppins';
    font-weight: 600;
    font-size: 42px;
    color: white;
}

.cleaning-subtitle{
    font-family: 'poppins';
    font-size: 18px;
    margin-top: 8px;
    color: white;
}
.more-details{
    width: 80%;
    display: flex;
    margin: 20px auto;
    align-items: center;
}

.sub-cleaning-img{
    width: 44%;
    height: 400px;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.cleaning-para{
    margin-left: 4%;
    padding: 40px 0; 
}

.cleaning-description{
    font-family: 'poppins-regular';
    font-size: 16px;
    color: #1E1E1E;
    line-height: 1.2;
    margin-top: 20px;
}

.sub-cleaning-description{
    display: flex;
    flex-wrap: wrap;
}

.sub-cleaning-subtitle{
    font-family: 'poppins-regular';
    font-size: 16px;
    color: #1E1E1E;
    line-height: 1.2;
    margin-top: 20px;
    font-weight: 700;
    width: 100%;
    flex: 50%;
}

.see-more-icon{
    width: fit-content;
    margin-top: 3vw;
    height: 80px;
    width: 80px;
    margin-left: auto;
    margin-right: auto;
}

@keyframes slideDown {
    from {
        max-height: 0;
        opacity: 0.6;
    }
    to {
        max-height: 1000px;
        opacity: 1;
    }
}

.hidden-details {
    display: none;
    max-height: 0;
    overflow: hidden;
    transition: max-height 1s ease-in-out, opacity 1s ease-in-out;
}

.hidden-details.visible {
    display: block;
    animation: slideDown 1s forwards;
}

.see-more-icon:hover  {
    animation: fadeIn 1s forwards; 
    opacity: 1; 
    background-color: #FFBA18;
    border-radius: 10px;
    cursor: pointer;
}

.center-text {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 600px) {
    .service-box {
        margin-top: 20px;
    }

    .cleaning-img{
        height: 180px;
    }

    .cleaning-details{
        width: 100%;
    }

    .cleaning-title{
        font-size: 24px;
    }

    .cleaning-subtitle{
        font-size: 16px;
    }

    .sub-cleaning-subtitle{
        flex: none;
    }

    .more-details{
        display: block;
        width: 96%;
    }

    .sub-cleaning-img{
        width: 100%;
        height: 200px;
    }

    .cleaning-para{
        margin-left: 0;
        padding: 20px 0; 
    }

    .cleaning-description{
        font-size: 14px;
    }

    .see-more-icon{
        height: 40px;
        width: 40px;
    }
}
footer{
    background-image: url('../../assests/svg/footer.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

}

.footer-container{
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    color: white;
}

.col1{
    width: 30%;
}

.footer-description{
    font-size: 16px;
    font-family: 'poppins';
    margin-top: 20px;
    line-height: 1.2;
    color: #F9F9F9;
}

.footer-title{
    font-family: 'poppins';
    font-weight: 600;
    font-size: 16px;
    color: #F9F9F9;
}

.footer-list{
    font-size: 16px;
    font-family: 'poppins';
    margin-top: 16px;
    line-height: 1.2;
    color: #96ADD1;
}

@media only screen and (max-width: 600px) {
    .footer-container{
        width: 96%;
        flex-wrap: wrap;
    }
    .col1{
        width: 100%;
    }

    .col2{
        width: 50%;
    }
}
.testimonials {
    margin-top: 80px;
    width: 100%;
}

.testimonial-cards-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.testimonial-name {
    font-size: 18px;
    font-weight: 600;
    color: #121212;
    margin-bottom: 0;
    margin-top: 40px;
}

.arrow-button {
    background: none;
    border: none;
    font-size: 2em;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.arrow-button.left {
    left: 10px;
}

.arrow-button.right {
    right: 10px;
}

.testimonial-cards {
    display: flex;
    gap: 20px;
    margin-top: 60px;
}

.testimonial-card-wrapper {
    max-width: 300px;
    text-align: center;
}

.testimonial-card {
    background-color: #fff;
    border-radius: 4px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
    height: 32vh;
    min-width: 200px;
}

.testimonial-card.center {
    background-color: #FFBA18;
    color: #fff;
}

.testimonial-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
}

.testimonial-position {
    color: #121212;
    font-size: 16px;
}

.testimonial-feedback {
    color: #121212;
    margin-top: 20px;
    font-size: 14px;
}

.rating {
    margin-top: 10px;
}

.star {
    color: #A1D6F6;
    font-size: 1.2em;
}

.star.filled {
    color: #016FC1;
}

.pagination {
    margin-top: 20px;
    text-align: center;
}

.pagination-dot {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0 5px;
    background-color: #ddd;
    border-radius: 50%;
    cursor: pointer;
}

.pagination-dot.active {
    background-color: #3498db;
}

.center-text {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.nav-header {
    display: flex;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    transition: background-color 0.3s ease;
    background-color: transparent;
    padding: 4px 0;
}

.nav-header.scrolled {
    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.left {
    flex: 60%;
    display: flex;
    align-items: left;
    margin-left: 10%;
}

.logo {
    width: 130px;
}

.menu {
    display: flex;
    align-items: center;
}

.menu-option {
    margin: 20px 2vw;
    cursor: pointer;
    font-family: 'poppins';
    color: #121212;
}

.menu-option:hover {
    color: #016FC1;
}

.right {
    flex: 40%;
    display: flex;
    justify-content: right;
    align-items: center;
    margin-right: 10%;
}

.nav-button {
    margin-left: 4px;
    cursor: pointer;
    font-family: 'poppins';
    color: white;
    padding: 12px 20px;
    border-radius: 50px;
    font-size: 14px;
    border: none;
    align-items: center;
    display: flex;
}

.call-button {
    background-color: #016FC1;
}

.quote-button {
    background-color: #121212;
}

.contact-icon {
    background: url('../../assests/images/phone-outgoing.png');
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin-right: 10px;
}

.quote-icon {
    background: url('../../assests/images/message-circle.png');
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin-right: 10px;
}

p {
    margin: 0;
}

.menu-icon {
    display: none;
}

/* Modal styles */
.modal {
    display: none;
    /* Hidden by default */
    position: fixed;
    top: 10px;
    right: 20px;
    width: 30%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    justify-content: center;
    align-items: center;
    z-index: 2000;
}

.modal-content {
    background-color: white;
    padding: 0 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 500px;
    text-align: center;
}

.modal.show {
    display: flex;
    /* Show the modal when the 'show' class is added */
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    width: 15vw;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #f1f1f1
}

.dropdown-content a.active {
    background-color: #016FC1;
    color: white;
}

.dropdown:hover .dropdown-content {
    display: block;
}

@media only screen and (max-width: 600px) {
    .header {
        width: 96%;
    }

    .left {
        margin-left: 2%;
    }

    .right {
        margin-right: 2%;
    }

    .menu {
        display: none;
    }

    .menu-icon {
        display: block;
        width: 30px;
        height: 30px;
        margin-left: auto;
        margin-right: 10px;
    }

    .menu-option {
        margin: 20px 1vw;
    }

    .nav-button {
        display: none;
    }

    .logo {
        width: 80px;
    }

    .dropdown-content {
        right: 0;
    }
}